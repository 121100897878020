import * as React from 'react';
import Box from '@mui/material/Box';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AddIcon from '@mui/icons-material/Add';
import CalculateIcon from '@mui/icons-material/Calculate';
import CategoryIcon from '@mui/icons-material/Category';
import {AppProvider} from '@toolpad/core/AppProvider';
import {DashboardLayout} from '@toolpad/core/DashboardLayout';
import type {Router, Navigation} from '@toolpad/core';
import {useAuth} from "../logic/AuthContext";
import NomenclaturesTable from "./NomenclaturesTable";
import {Drawer, Tooltip} from "@mui/material";
import {ruRU} from '@mui/x-data-grid/locales';
import {useLocation, useNavigate} from "react-router-dom";
import CalculationsTable from "./CalculationsTable";
import CalculationPage from "./CalculationPage";
import {useState} from "react";

const NAVIGATION: Navigation = [
  {
    segment: 'create-calculation',
    title: 'Создать расчёт',
    icon: (
      <Tooltip title="Создать расчёт">
        <AddIcon />
      </Tooltip>
    ),
  },
  {
    segment: 'my-calculations',
    title: 'Мои расчёты',
    icon: (
      <Tooltip title="Мои расчёты">
        <CalculateIcon />
      </Tooltip>
    ),
  },
  {
    segment: 'nomenclatures',
    title: 'Номенклатура',
    icon: (
      <Tooltip title="Номенклатура">
        <CategoryIcon />
      </Tooltip>
    ),
  },
  {
    segment: 'exit',
    title: 'Выход',
    icon: (
      <Tooltip title="Выход">
        <ExitToAppIcon />
      </Tooltip>
    ),
  },
];

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: {light: true},
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
}, ruRU);

function DemoPageContent({pathname}: { pathname: string }) {
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (pathname === '/nomenclatures') {
      window.history.replaceState(null, '', '/nomenclatures');
    }
    if (pathname === '/my-calculations' ) {
      window.history.replaceState(null, '', '/my-calculations');
    }
    if (pathname === '/create-calculation') {
      window.history.replaceState(null, '', '/create-calculation');
    }
    if (pathname === '/exit') {
      auth.logOut();
    }
  }, [pathname, auth]);

  return (
    <>
      {pathname === '/nomenclatures' && (
        <NomenclaturesTable/>
      )}
      {pathname === '/my-calculations' && (
        <CalculationsTable/>
      )}
      {pathname.startsWith('/create-calculation') && (
        <CalculationPage />
      )}
    </>
  );
}

interface DemoProps {
  window?: () => Window;
}

export default function Dashboard(props: DemoProps) {
  const {window} = props;
  const location = useLocation();
  const [pathname, setPathname] = React.useState(location.pathname);
  const auth = useAuth();
  const router = React.useMemo<Router>(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  const demoWindow = window !== undefined ? window() : undefined;

  return (
    <ThemeProvider theme={demoTheme}>
      <AppProvider
        navigation={NAVIGATION}
        router={router}
        theme={demoTheme}
        window={demoWindow}
        branding={{
          logo: <> </>,
          title: `Расчётчик - ${auth.user.username}`,
        }}
      >
        <DashboardLayout disableCollapsibleSidebar={true}>
          <DemoPageContent pathname={pathname}/>
        </DashboardLayout>
      </AppProvider>
    </ThemeProvider>
  );
}