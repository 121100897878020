import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, Alert } from '@mui/material';
import {useAuth} from "../logic/AuthContext";

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const auth = useAuth();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowAlert(false);

    const successLogin = await auth.loginAction(username, password);
    if (!successLogin) {
      setShowAlert(true);
    }

  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Paper elevation={3} style={{ padding: '2rem', width: '70%' }}>
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            sx={{
              color: '#2566af',
              padding: '0.5rem',
              borderRadius: '4px',
              fontFamily: 'Roboto, sans-serif',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Расчётчик <sup>0.1</sup>
          </Typography>
          {showAlert && (
            <Alert severity="error" sx={{ marginBottom: '1rem' }}>
              Пользователь не найден
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                fullWidth
                id="username"
                label="Имя пользователя"
                variant="outlined"
                name="username"
                size="small"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                id="password"
                label="Пароль"
                type="password"
                variant="outlined"
                name="password"
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Войти
            </Button>
          </form>
          <Typography
            variant="caption"
            display="block"
            sx={{
              color: 'grey',
              textAlign: 'center',
              marginTop: '1rem',
            }}
          >
            © 2024 ТОО «Стальная марка»
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default Login;