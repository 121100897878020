import React, {useEffect, useRef, useState} from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider, FormControl,
  FormControlLabel, FormLabel,
  MenuItem,
  Modal, Radio, RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import {useAuth} from '../logic/AuthContext';
import Grid from '@mui/material/Grid2';
import {
  DataGrid,
  GridColDef,
  GridSlotsComponentsProps,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {Calculation, CalculationItem, Product, ValueType} from "../logic/Models";
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    total?: number;
  }
}

const statusMap: { [key: string]: string } = {
  DRAFT: 'Черновик',
  PROJECT: 'Проект',
  READY: 'Готово',
  SENT: 'Отправлено',
};

const currencyFormatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'KZT',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function CustomFooterStatusComponent(
  props: NonNullable<GridSlotsComponentsProps['footer']>,
) {
  return (
    <Box sx={{p: 2, display: 'flex', justifyContent: 'flex-end'}}>
      <b>Итого: {props.total ? currencyFormatter.format(props.total) : '0'}</b>
    </Box>
  );
}


const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  boxShadow: 8,
  p: 4,
};

const roundNum = (num: number) => {
  return Math.round(num * 100) / 100
}

const CalculationPage: React.FC = () => {
  const [calc, setCalc] = useState<Calculation | null>(null);
  const [error, setError] = useState('');
  const [modalError, setModalError] = useState('');
  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false);
  const [newItem, setNewItem] = useState<CalculationItem>(new CalculationItem({calculation: calc?.id}));
  const [userKgRaw, setUserKgRaw] = useState('');
  const [userMRaw, setUserMRaw] = useState('');
  const [userCountRaw, setUserCountRaw] = useState('');
  const [userAreaRaw, setUserAreaRaw] = useState('');
  const [pricePerKgRaw, setPricePerKgRaw] = useState('');
  const [pricePerPieceRaw, setPricePerPieceRaw] = useState('');
  const [pricePerMRaw, setPricePerMRaw] = useState('');
  const [pricePerAreaRaw, setPricePerAreaRaw] = useState('');
  const [nomenclature, setNomenclature] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [radioValue, setRadioValue] = React.useState('EXISTING');
  const [customWidth, setCustomWidth] = useState('');
  const [customLength, setCustomLength] = useState('');
  const [customDepth, setCustomDepth] = useState('');
  const auth = useAuth();
  const prevCalcJson = useRef<string | null>(null);

  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: ' ',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        // Check if calc and calc.items are defined
        if (!calc || !calc.items) {
          return null;
        }

        const index = calc.items.findIndex((item) => item.id === params.id);

        const moveUp = (e: any) => {
          if (!calc || !calc.items) {
            return null;
          }
          e.stopPropagation();
          if (index <= 0) return;
          const newItems = [...calc.items];
          [newItems[index - 1], newItems[index]] = [newItems[index], newItems[index - 1]];
          setCalc(new Calculation({ ...calc, items: newItems }));
        };

        const moveDown = (e: any) => {
          if (!calc || !calc.items) {
            return null;
          }
          e.stopPropagation();
          if (index >= calc.items.length - 1) return;
          const newItems = [...calc.items];
          [newItems[index], newItems[index + 1]] = [newItems[index + 1], newItems[index]];
          setCalc(new Calculation({ ...calc, items: newItems }));
        };

        const deleteItem = (e: any) => {
          if (!calc || !calc.items) {
            return null;
          }
          e.stopPropagation();
          setCalc(new Calculation({ ...calc, items: calc.items.filter((item) => item.id !== params.id) }));
        };

        return (
          <Box display="flex" alignItems="center" marginTop={2}>
            <IconButton
              onClick={moveUp}
              disabled={index <= 0}
              sx={{ padding: 0, marginRight: 0.5 }}
            >
              <ArrowUpwardIcon sx={{ color: 'grey' }} />
            </IconButton>
            <IconButton
              onClick={moveDown}
              disabled={index >= calc.items.length - 1}
              sx={{ padding: 0, marginRight: 0.5 }}
            >
              <ArrowDownwardIcon sx={{ color: 'grey' }} />
            </IconButton>
            <IconButton
              sx={{ padding: 0, marginRight: 0.5 }}
            >
              <EditIcon sx={{ color: 'grey' }} />
            </IconButton>
            <IconButton onClick={deleteItem} sx={{ padding: 0 }}>
              <DeleteIcon sx={{ color: 'grey' }} />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: '#',
      headerName: '№',
      width: 50,
      valueGetter: (value, row, column, apiRef) => {
        if (row.id && apiRef.current.getRowIndexRelativeToVisibleRows(row.id) >= 0) {
          return apiRef.current.getRowIndexRelativeToVisibleRows(row.id) + 1
        } else {
          return ''
        }
      }
    },
    {field: 'title', headerName: 'Наименование', width: 400, valueGetter: (value, row) => row.get_title() || ''},
    {
      field: 'count',
      headerName: 'Кол-во',
      width: 100,
      valueGetter: (value, row) => row.get_count() || ''
    },
    {
      field: 'price_per_measure',
      headerName: 'Цена за ед.',
      width: 150,
      valueGetter: (value, row) => row.get_price_per_count() || ''
    },
    {
      field: 'total',
      headerName: 'Итого',
      width: 150,
      valueGetter: (value, row) => row.get_total_price() || ''
    },
    {
      field: 'delivery_time',
      headerName: 'Срок поставки',
      width: 150,
      valueGetter: (value, row) => row.delivery_time || ''
    },

  ];

  const handleClickExportToPDFKg = async () => {
    if (!calc) return;

    const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/v1/calculations/${calc?.id}/export/`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${auth.token}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `КП ${calc?.title}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  }

  const handleClickExportToPDFInvoice = async () => {
    if (!calc) return;

    const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/v1/calculations/${calc?.id}/export-invoice/`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${auth.token}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `КП ${calc?.title}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarDensitySelector/>
        <Box sx={{flexGrow: 1}}/>
        <Button
          color="primary"
          variant={"contained"}
          onClick={() => {
            setSelectedProduct(null);
            setNewItem(new CalculationItem({calculation: calc?.id}));
            setUserKgRaw('');
            setUserMRaw('');
            setUserCountRaw('');
            setUserAreaRaw('');
            setPricePerKgRaw('');
            setPricePerPieceRaw('');
            setPricePerMRaw('');
            setPricePerAreaRaw('');
            setCustomDepth('');
            setCustomLength('');
            setCustomWidth('');
            setRadioValue('EXISTING');
            setAddProductDialogOpen(true)
          }}
          size={'small'}
          startIcon={<AddIcon/>}
        >
          Добавить товар
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleClickExportToPDFKg}
          size={'small'}
          startIcon={<DownloadIcon/>}
        >
          Скачать КП
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleClickExportToPDFInvoice}
          size={'small'}
          startIcon={<DownloadIcon/>}
        >
          Скачать счет
        </Button>
      </GridToolbarContainer>
    );
  }


  const createCalculation = async () => {
    if (calc) return;
    const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/v1/calculations/`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${auth.token}`,
      },
    });
    return await response.json();
  };

  const fetchCalculation = async (id: number) => {
    const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/v1/calculations/${id}/`, {
      headers: {
        'Authorization': `Token ${auth.token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setCalc(new Calculation(data));
      setNewItem(new CalculationItem({...newItem, calculation: data.id}));
      setError('');
    } else {
      if (response.status === 404) {
        setError('Расчёт не найден');
      } else {
        setError('Произошла ошибка');
      }
    }
  };

  const fetchNomenclature = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/v1/products/?page_size=100000`, {
      headers: {
        'Authorization': `Token ${auth.token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      const products = data.results.map((item: any) => Object.assign(new Product({}), item));
      setNomenclature(products);
    }
  };

  const updateCalc = async () => {
    if (!calc) return;
    const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/v1/calculations/${calc.id}/`, {
      method: 'PUT',
      headers: {
        'Authorization': `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(calc.toPlainObject()),
    });
    if (response.ok) {
      const data = await response.json();
      setCalc(new Calculation(data));
      setError('');
    } else {
      setError('Произошла ошибка');
    }
    setIsLoading(false);
  };

  const handleAddItem = () => {
    if (!newItem.product && radioValue === 'EXISTING') {
      setModalError('Выберите товар');
      return
    }

    if (!newItem.value_type) {
      setModalError('Выберите метод расчёта');
      return
    }

    if (newItem.value_type === ValueType.MASS && !newItem.user_kg) {
      setModalError('Введите массу от клиента');
      return
    }

    if (newItem.value_type === ValueType.LENGTH && !newItem.user_m) {
      setModalError('Введите длину от клиента');
      return
    }

    if (newItem.value_type === ValueType.COUNT && !newItem.amount) {
      setModalError('Введите количество от клиента');
      return
    }

    setModalError('');

    const updatedItems = (calc?.items || []).map((item) =>
      item.id === newItem.id ? newItem : item
    );

    const itemExists = (calc?.items || []).some((item) => item.id === newItem.id);
    const newItems = itemExists ? updatedItems : [...updatedItems, newItem];

    setCalc(new Calculation({...calc, items: newItems}));

    setNewItem(new CalculationItem({calculation: calc?.id}));
    setSelectedProduct(null);
    setUserKgRaw('');
    setUserMRaw('');
    setUserCountRaw('');
    setUserAreaRaw('');
    setPricePerKgRaw('');
    setPricePerPieceRaw('');
    setPricePerMRaw('');
    setPricePerAreaRaw('');
    setCustomDepth('');
    setCustomLength('');
    setCustomWidth('');
    setAddProductDialogOpen(false);
  }

  const updateCountableExistingItemRoundedValues = (item: CalculationItem) => {
    if (item.value_type === ValueType.MASS && item.user_kg && item?.product?.kg_per_piece) {
      item.amount = Math.ceil(item.user_kg / item?.product?.kg_per_piece);
      item.rounded_kg = roundNum(item.amount * item?.product?.kg_per_piece);
      if (item?.product?.length_m) {
        item.rounded_m = roundNum(item.amount * item?.product?.length_m);
      }
      if (item?.product?.area_per_piece) {
        item.rounded_area = roundNum(item.amount * item?.product?.area_per_piece);
      }
    }
    if (item.value_type === ValueType.LENGTH && item.user_m && item?.product?.length_m) {
      item.amount = Math.ceil(item.user_m / item?.product?.length_m);
      item.rounded_m = roundNum(item.amount * item?.product?.length_m);
      if (item?.product?.kg_per_piece) {
        item.rounded_kg = roundNum(item.amount * item?.product?.kg_per_piece);
      }
      if (item?.product?.area_per_piece) {
        item.rounded_area = roundNum(item.amount * item?.product?.area_per_piece);
      }
    }
    if (item.value_type === ValueType.AREA && item.user_area && item?.product?.area_per_piece) {
      item.amount = Math.ceil(item.user_area / item?.product?.area_per_piece);
      item.rounded_area = roundNum(item.amount * item?.product?.area_per_piece);
      if (item?.product?.length_m) {
        item.rounded_m = roundNum(item.amount * item?.product?.length_m);
      }
      if (item?.product?.kg_per_piece) {
        item.rounded_kg = roundNum(item.amount * item?.product?.kg_per_piece);
      }
    }
    if (item.value_type === ValueType.COUNT && newItem.amount) {
      item.amount = newItem.amount;
      if (item?.product?.length_m) {
        item.rounded_m = roundNum(item.amount * item?.product?.length_m);
      }
      if (item?.product?.kg_per_piece) {
        item.rounded_kg = roundNum(item.amount * item?.product?.kg_per_piece);
      }
      if (item?.product?.area_per_piece) {
        item.rounded_area = roundNum(item.amount * item?.product?.area_per_piece);
      }
    }
  }

  const updateCountableCustomItemRoundedValues = (item: CalculationItem) => {
    if (item.value_type === ValueType.MASS && item.user_kg && item.custom_kg_per_piece) {
      item.amount = Math.ceil(item.user_kg / item.custom_kg_per_piece);
      item.rounded_kg = roundNum(item.amount * item.custom_kg_per_piece);
      if (item.custom_length_m) {
        item.rounded_m = roundNum(item.amount * item.custom_length_m);
      }
      if (item.custom_area_per_piece) {
        item.rounded_area = roundNum(item.amount * item.custom_area_per_piece);
      }
    }
    if (item.value_type === ValueType.LENGTH && item.user_m && item.custom_length_m) {
      item.amount = Math.ceil(item.user_m / item.custom_length_m);
      item.rounded_m = roundNum(item.amount * item.custom_length_m);
      if (item.custom_kg_per_piece) {
        item.rounded_kg = roundNum(item.amount * item.custom_kg_per_piece);
      }
      if (item.custom_area_per_piece) {
        item.rounded_area = roundNum(item.amount * item.custom_area_per_piece);
      }
    }
    if (item.value_type === ValueType.AREA && item.user_area && item.custom_area_per_piece) {
      item.amount = Math.ceil(item.user_area / item.custom_area_per_piece);
      item.rounded_area = roundNum(item.amount * item.custom_area_per_piece);
      if (item.custom_length_m) {
        item.rounded_m = roundNum(item.amount * item.custom_length_m);
      }
      if (item.custom_kg_per_piece) {
        item.rounded_kg = roundNum(item.amount * item.custom_kg_per_piece);
      }
    }
    if (item.value_type === ValueType.COUNT && newItem.amount) {
      item.amount = newItem.amount;
      if (item.custom_length_m) {
        item.rounded_m = roundNum(item.amount * item.custom_length_m);
      }
      if (item.custom_kg_per_piece) {
        item.rounded_kg = roundNum(item.amount * item.custom_kg_per_piece);
      }
      if (item.custom_area_per_piece) {
        item.rounded_area = roundNum(item.amount * item.custom_area_per_piece);
      }
    }
  }

  const updateUncountableItemRoundedValues = (item: CalculationItem) => {
    if (item.value_type === ValueType.MASS && item.user_kg) {
      item.rounded_kg = roundNum(item.user_kg);
      item.amount = null;
      item.rounded_m = null;
      item.rounded_area = null;
      if (item.product?.kg_per_m) {
        item.rounded_m = roundNum(item.user_kg / item.product.kg_per_m);
      }
    }
    if (item.value_type === ValueType.LENGTH && item.user_m) {
      item.rounded_m = roundNum(item.user_m);
      item.amount = null;
      item.rounded_kg = null;
      item.rounded_area = null;
      if (item.product?.kg_per_m) {
        item.rounded_kg = roundNum(item.user_m * item.product.kg_per_m);
      }
    }
    if (item.value_type === ValueType.AREA && item.user_area) {
      item.rounded_area = roundNum(item.user_area);
      item.amount = null;
      item.rounded_kg = null;
      item.rounded_m = null;
    }
  }

  const updateRoundedValues = (item: CalculationItem) => {
    if (item?.product?.is_uncountable) {
      updateUncountableItemRoundedValues(item);
    } else {
      if (radioValue === 'EXISTING') {
        updateCountableExistingItemRoundedValues(item);
      } else {
        updateCountableCustomItemRoundedValues(item);
      }
    }
  }

  const handleProductChange = (e: any, value: Product | null) => {
    setSelectedProduct(value);
    const refreshedItem = new CalculationItem(
      {
        ...newItem,
        product: value,
        price_per_m: value?.price_per_m ? roundNum(value?.price_per_m) : null,
        price_per_kg: value?.price_per_kg ? roundNum(value?.price_per_kg) : null,
        price_per_piece: value?.price_per_piece ? roundNum(value?.price_per_piece) : null,
        price_per_area: value?.price_per_area ? roundNum(value?.price_per_area) : null,
        amount: null,
        rounded_kg: null,
        rounded_m: null,
        rounded_area: null,
        custom_title: null,
        custom_size: null,
        custom_suffix: null,
        custom_kg_per_piece: null,
        custom_length_m: null,
        custom_area_per_piece: null,
      }
    );

    setPricePerPieceRaw(refreshedItem.price_per_piece?.toString() || '');
    setPricePerKgRaw(refreshedItem.price_per_kg?.toString() || '');
    setPricePerMRaw(refreshedItem.price_per_m?.toString() || '');
    setPricePerAreaRaw(refreshedItem.price_per_area?.toString() || '');

    updateRoundedValues(refreshedItem);
    setNewItem(refreshedItem);
  }

  const handleValueTypeChange = (e: any) => {
    const refreshedItem = new CalculationItem({
      ...newItem,
      value_type: e.target.value,
      amount: null,
      rounded_kg: null,
      rounded_m: null,
      rounded_area: null,
      user_kg: null,
      user_m: null,
      user_area: null,
    });
    if (refreshedItem.value_type === ValueType.MASS) {
      refreshedItem.user_kg = newItem.user_kg;
    }
    if (refreshedItem.value_type === ValueType.LENGTH) {
      refreshedItem.user_m = newItem.user_m;
    }
    if (refreshedItem.value_type === ValueType.AREA) {
      refreshedItem.user_area = newItem.user_area;
    }
    updateRoundedValues(refreshedItem);
    setNewItem(refreshedItem);
  }

  const handleUserKgChange = (e: any) => {
    const textValue = e.target.value.replace(/[^0-9.]/g, '');
    setUserKgRaw(textValue);

    const refreshedItem = new CalculationItem({
      ...newItem,
      user_kg: parseFloat(textValue),
      amount: null,
      rounded_kg: null,
      rounded_m: null,
      rounded_area: null,
    });

    updateRoundedValues(refreshedItem);
    setNewItem(refreshedItem);
  }

  const handleUserMChange = (e: any) => {
    const textValue = e.target.value.replace(/[^0-9.]/g, '');
    setUserMRaw(textValue);

    const refreshedItem = new CalculationItem({
      ...newItem,
      user_m: parseFloat(textValue),
      amount: null,
      rounded_kg: null,
      rounded_m: null,
      rounded_area: null,
    });

    updateRoundedValues(refreshedItem);
    setNewItem(refreshedItem);
  }

  const handleUserAreaChange = (e: any) => {
    const textValue = e.target.value.replace(/[^0-9.]/g, '');
    setUserAreaRaw(textValue);

    const refreshedItem = new CalculationItem({
      ...newItem,
      user_area: parseFloat(textValue),
      amount: null,
      rounded_kg: null,
      rounded_m: null,
      rounded_area: null,
    });

    updateRoundedValues(refreshedItem);
    setNewItem(refreshedItem);
  }

  const handlePricePerKgChange = (e: any) => {
    const textValue = e.target.value.replace(/[^0-9.]/g, '');
    setPricePerKgRaw(textValue);

    const item = new CalculationItem({
      ...newItem,
      price_per_kg: parseFloat(textValue),
      price_per_piece: null,
      price_per_m: null,
      price_per_area: null,
    });

    if (radioValue === 'EXISTING') {
      if (item.product?.kg_per_piece && item.price_per_kg) {
        item.price_per_piece = roundNum(item.product.kg_per_piece * item.price_per_kg);
        if (item.product?.length_m) {
          item.price_per_m = roundNum(item.price_per_piece / item.product.length_m);
        }
        if (item.product?.area_per_piece) {
          item.price_per_area = roundNum(item.price_per_piece / item.product.area_per_piece);
        }
      }
    } else {
      if (item.custom_kg_per_piece && item.price_per_kg) {
        item.price_per_piece = roundNum(item.custom_kg_per_piece * item.price_per_kg);
        if (item.custom_length_m) {
          item.price_per_m = roundNum(item.price_per_piece / item.custom_length_m);
        }
        if (item.custom_area_per_piece) {
          item.price_per_area = roundNum(item.price_per_piece / item.custom_area_per_piece);
        }
      }
    }

    setNewItem(item);
  }

  const handlePricePerPieceChange = (e: any) => {
    const textValue = e.target.value.replace(/[^0-9.]/g, '');
    setPricePerPieceRaw(textValue);

    const item = new CalculationItem({
      ...newItem,
      price_per_piece: parseFloat(textValue),
      price_per_kg: null,
      price_per_m: null,
      price_per_area: null,
    });

    if (radioValue === 'EXISTING') {
      if (item.product?.kg_per_piece && item.price_per_piece) {
        item.price_per_kg = roundNum(item.price_per_piece / item.product.kg_per_piece);
        if (item.product?.length_m) {
          item.price_per_m = roundNum(item.price_per_piece / item.product.length_m);
        }
        if (item.product?.area_per_piece) {
          item.price_per_area = roundNum(item.price_per_piece / item.product.area_per_piece);
        }
      }
    } else {
      if (item.custom_kg_per_piece && item.price_per_piece) {
        item.price_per_kg = roundNum(item.price_per_piece / item.custom_kg_per_piece);
        if (item.custom_length_m) {
          item.price_per_m = roundNum(item.price_per_piece / item.custom_length_m);
        }
        if (item.custom_area_per_piece) {
          item.price_per_area = roundNum(item.price_per_piece / item.custom_area_per_piece);
        }
      }
    }
    setNewItem(item);
  }

  const handlePricePerMChange = (e: any) => {
    const textValue = e.target.value.replace(/[^0-9.]/g, '');
    setPricePerMRaw(textValue);

    const item = new CalculationItem({
      ...newItem,
      price_per_m: parseFloat(textValue),
      price_per_kg: null,
      price_per_piece: null,
      price_per_area: null,
    });

    if (radioValue === 'EXISTING') {
      if (item.product?.length_m && item.price_per_m) {
        item.price_per_piece = roundNum(item.price_per_m * item.product.length_m);
        if (item.product?.kg_per_piece) {
          item.price_per_kg = roundNum(item.price_per_piece / item.product.kg_per_piece);
        }
        if (item.product?.area_per_piece) {
          item.price_per_area = roundNum(item.price_per_piece / item.product.area_per_piece);
        }
      }
    } else {
      if (item.custom_length_m && item.price_per_m) {
        item.price_per_piece = roundNum(item.price_per_m * item.custom_length_m);
        if (item.custom_kg_per_piece) {
          item.price_per_kg = roundNum(item.price_per_piece / item.custom_kg_per_piece);
        }
        if (item.custom_area_per_piece) {
          item.price_per_area = roundNum(item.price_per_piece / item.custom_area_per_piece);
        }
      }
    }
    setNewItem(item);
  }

  const handlePricePerAreaChange = (e: any) => {
    const textValue = e.target.value.replace(/[^0-9.]/g, '');
    setPricePerAreaRaw(textValue);

    const item = new CalculationItem({
      ...newItem,
      price_per_area: parseFloat(textValue),
      price_per_kg: null,
      price_per_piece: null,
      price_per_m: null,
    });

    if (radioValue === 'EXISTING') {
      if (item.product?.area_per_piece && item.price_per_area) {
        item.price_per_piece = roundNum(item.price_per_area * item.product.area_per_piece);
        if (item.product?.kg_per_piece) {
          item.price_per_kg = roundNum(item.price_per_piece / item.product.kg_per_piece);
        }
        if (item.product?.length_m) {
          item.price_per_m = roundNum(item.price_per_piece / item.product.length_m);
        }
      }
    } else {
      if (item.custom_area_per_piece && item.price_per_area) {
        item.price_per_piece = roundNum(item.price_per_area * item.custom_area_per_piece);
        if (item.custom_kg_per_piece) {
          item.price_per_kg = roundNum(item.price_per_piece / item.custom_kg_per_piece);
        }
        if (item.custom_length_m) {
          item.price_per_m = roundNum(item.price_per_piece / item.custom_length_m);
        }
      }
    }
    setNewItem(item);
  }

  const handleAmountChange = (e: any) => {
    const textValue = e.target.value.replace(/[^0-9.]/g, '');
    setUserCountRaw(textValue);

    const item = new CalculationItem({
      ...newItem,
      amount: parseFloat(textValue),
      rounded_kg: null,
      rounded_m: null,
      rounded_area: null,
    });

    if (radioValue === 'EXISTING') {
      if (item.amount) {
        if (item?.product?.length_m) {
          item.rounded_m = roundNum(item.amount * item?.product?.length_m);
        }
        if (item?.product?.kg_per_piece) {
          item.rounded_kg = roundNum(item.amount * item?.product?.kg_per_piece);
        }
        if (item?.product?.area_per_piece) {
          item.rounded_area = roundNum(item.amount * item?.product?.area_per_piece);
        }
      }
    } else {
      if (item.amount) {
        if (item.custom_length_m) {
          item.rounded_m = roundNum(item.amount * item.custom_length_m);
        }
        if (item.custom_kg_per_piece) {
          item.rounded_kg = roundNum(item.amount * item.custom_kg_per_piece);
        }
        if (item.custom_area_per_piece) {
          item.rounded_area = roundNum(item.amount * item.custom_area_per_piece);
        }
      }
    }

    setNewItem(item);
  }

  const openItemToEdit = (id: number) => {
    const item = calc?.items?.find((item) => item.id === id);
    if (!item) return;

    const refreshedItem = new CalculationItem(item);
    if(refreshedItem.user_kg) {
      refreshedItem.value_type = ValueType.MASS;
    }
    else if(refreshedItem.user_m) {
      refreshedItem.value_type = ValueType.LENGTH;
    }
    else if(refreshedItem.user_area) {
      refreshedItem.value_type = ValueType.AREA;
    }
    else if(refreshedItem.amount) {
      refreshedItem.value_type = ValueType.COUNT;
    }
    setNewItem(refreshedItem);

    if (item.product) {
      setSelectedProduct(new Product(item.product));
    }
    if (item.user_kg) {
      setUserKgRaw(item.user_kg.toString());
    }
    if (item.user_m) {
      setUserMRaw(item.user_m.toString());
    }
    if (item.user_area) {
      setUserAreaRaw(item.user_area.toString());
    }
    if (item.amount) {
      setUserCountRaw(item.amount.toString());
    }
    if (item.price_per_kg) {
      setPricePerKgRaw(item.price_per_kg.toString());
    }
    if (item.price_per_m) {
      setPricePerMRaw(item.price_per_m.toString());
    }
    if (item.price_per_area) {
      setPricePerAreaRaw(item.price_per_area.toString());
    }
    if (item.price_per_piece) {
      setPricePerPieceRaw(item.price_per_piece.toString());
    }

    setAddProductDialogOpen(true);
  }

  const changeRadioValue = (code: string) => {
    setRadioValue(code);

    const refreshedItem = new CalculationItem(newItem);

    if (code==='EXISTING') {
      refreshedItem.custom_title = null;
      refreshedItem.custom_size = null;
      refreshedItem.custom_suffix = null;
      refreshedItem.custom_kg_per_piece = null;
      refreshedItem.custom_length_m = null;
      refreshedItem.custom_area_per_piece = null;
    } else if (code==='CUSTOM') {
      refreshedItem.product = null;

      if (newItem?.product) {
        refreshedItem.custom_title = newItem?.product?.title;
        refreshedItem.custom_size = newItem?.product?.size;
        refreshedItem.custom_suffix = newItem?.product?.suffix;
        // if (newItem?.product?.kg_per_piece) {
        //   refreshedItem.custom_kg_per_piece = newItem?.product?.kg_per_piece;
        // }
        // if (newItem?.product?.length_m) {
        //   refreshedItem.custom_length_m = newItem?.product?.length_m;
        // }
        // if (newItem?.product?.area_per_piece) {
        //   refreshedItem.custom_area_per_piece = newItem?.product?.area_per_piece;
        // }
      }
    }

    setNewItem(refreshedItem);
  }

  useEffect(() => {
    if (calc) return;
    const match = window.location.pathname.match(/\/create-calculation\/(\d+)/);
    if (match) {
      fetchCalculation(parseInt(match[1]));
    } else {
      createCalculation().then((data) => {
        setCalc(new Calculation(data));
        setNewItem(new CalculationItem({...newItem, calculation: data.id}));
        window.history.replaceState(null, '', '/create-calculation/' + data.id);
      });
    }
    fetchNomenclature();
  }, []);

  useEffect(() => {
    if (calc && calc.id) {
      const currentCalcJson = JSON.stringify(new Calculation({
        ...calc,
        items: calc.items?.map(({id, ...rest}) => new CalculationItem({...rest, id: 0}))
      }));
      if (!prevCalcJson.current) {
        prevCalcJson.current = currentCalcJson;
        return;
      }

      if (prevCalcJson.current !== currentCalcJson) {
        prevCalcJson.current = currentCalcJson;
        setIsLoading(true);
        const timer = setTimeout(() => {
          updateCalc();
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
  }, [calc]);

  useEffect(() => {
    if (!calc) return;
    if (!customWidth || !customLength || !customDepth) return;
    if (radioValue !== 'CUSTOM') return;

    const width = parseFloat(customWidth.replace(/[^0-9.]/g, ''));
    const length = parseFloat(customLength.replace(/[^0-9.]/g, ''));
    const depth = parseFloat(customDepth.replace(/[^0-9.]/g, ''));

    const massKg = width * length * depth * 9.5 / 1000000;
    const areaM = width * length / 1000000;
    const lengthM = length / 1000;

    const refreshedItem = new CalculationItem(
      newItem,
    );

    refreshedItem.custom_kg_per_piece = roundNum(massKg);
    refreshedItem.custom_length_m = roundNum(lengthM);
    refreshedItem.custom_area_per_piece = roundNum(areaM);

    refreshedItem.custom_size = `${length}x${width}x${depth}`;

    setNewItem(refreshedItem);

  }, [customWidth, customLength, customDepth]);

  return (
    <Box style={{height: '85vh', width: '100%'}}>
      {error && (
        <Alert severity="error" sx={{marginBottom: '1rem'}}>
          {error}
        </Alert>
      )}
      <Modal
        open={addProductDialogOpen}
        onClose={() => setAddProductDialogOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {modalError && (
            <Alert severity="error" sx={{marginBottom: '1rem'}}>
              {modalError}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid size={12}>
              <FormControl>
                <RadioGroup row>
                  <FormControlLabel
                    checked={radioValue === 'EXISTING'}
                    value="EXISTING"
                    onChange={(e) => changeRadioValue("EXISTING")}
                    control={<Radio />}
                    label="Товар из номенклатуры"
                  />
                  <FormControlLabel
                    checked={radioValue === 'CUSTOM'}
                    value="CUSTOM"
                    onChange={(e) => changeRadioValue("CUSTOM")}
                    control={<Radio />}
                    label="Вбить товар вручную"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {radioValue === 'EXISTING' && (
              <>
                <Grid size={12}>
                  <Autocomplete
                    options={nomenclature}
                    getOptionLabel={(option) => option.full_title()}
                    getOptionKey={(option) => option.id || option.full_title()}
                    renderInput={(params) => <TextField {...params} label="Товар" variant="outlined"/>}
                    style={{marginBottom: '1rem'}}
                    onChange={handleProductChange}
                    value={selectedProduct}
                  />
                </Grid>
                {newItem?.product?.is_uncountable && (
                  <Grid size={12}>
                    <Alert variant="standard" severity="info">
                      Внимание! Товар не мерный, поэтому точная масса/длина/площадь за одну штуку отсутствует.
                      Автоматическое округление не производится, количество штук не подсчитывается.
                    </Alert>
                  </Grid>
                )}

              </>
            )}
            {radioValue === 'CUSTOM' && (<>
              <Grid size={4}>
                <TextField
                  label="Наименование"
                  fullWidth
                  value={newItem?.custom_title}
                  onChange={(e) => {
                    setNewItem(new CalculationItem({
                      ...newItem,
                      custom_title: e.target.value
                    }));
                  }}
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  label="Размер"
                  fullWidth
                  value={newItem?.custom_size}
                  onChange={(e) => {
                    setNewItem(new CalculationItem({
                      ...newItem,
                      custom_size: e.target.value
                    }));
                  }}
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  label="Суффикс"
                  fullWidth
                  value={newItem?.custom_suffix}
                  onChange={(e) => {
                    setNewItem(new CalculationItem({
                      ...newItem,
                      custom_suffix: e.target.value
                    }));
                  }}
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  label="Длина (мм)"
                  fullWidth
                  value={customLength}
                  onChange={(e) => setCustomLength(e.target.value)}
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  label="Ширина (мм)"
                  fullWidth
                  value={customWidth}
                  onChange={(e) => setCustomWidth(e.target.value)}
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  label="Толщина (мм)"
                  fullWidth
                  value={customDepth}
                  onChange={(e) => setCustomDepth(e.target.value)}
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  label="Вес за шт. (кг)"
                  fullWidth
                  disabled
                  value={String(newItem?.custom_kg_per_piece || '')}
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  label="Длина за шт.(м)"
                  fullWidth
                  disabled
                  value={String(newItem?.custom_length_m || '')}
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  label="Площадь за шт.(м)"
                  fullWidth
                  disabled
                  value={String(newItem?.custom_area_per_piece || '')}
                />
              </Grid>
            </>)}

            <Grid size={12}>
              <Divider sx={{borderColor: 'grey.500'}}>
                <Typography variant="subtitle1" color="textSecondary">
                  Вводные данные
                </Typography>
              </Divider>
            </Grid>
            <Grid size={3}>
              <TextField
                label="Рассчитать по"
                value={newItem.value_type || ''}
                onChange={handleValueTypeChange}
                fullWidth
                select
              >
                <MenuItem value={ValueType.MASS}>
                  Массе
                </MenuItem>
                {(newItem?.product?.length_m || newItem?.custom_length_m || newItem?.product?.is_uncountable) && (
                  <MenuItem value={ValueType.LENGTH}>
                    Длине
                  </MenuItem>
                )}
                {(newItem?.product?.area_per_piece || newItem?.custom_area_per_piece || newItem?.product?.is_uncountable) && (
                  <MenuItem value={ValueType.AREA}>
                    Площади
                  </MenuItem>
                )}
                {!newItem?.product?.is_uncountable && (
                  <MenuItem value={ValueType.COUNT}>
                    Количеству
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            {newItem.value_type === ValueType.MASS && (
              <>
                <Grid size={3}>
                  <TextField
                    label="Масса от клиента (кг)"
                    fullWidth
                    value={userKgRaw}
                    onChange={handleUserKgChange}
                  />
                </Grid>
                <Grid size={3}>
                  <TextField
                    label="Цена за кг (тг)"
                    fullWidth
                    value={pricePerKgRaw}
                    onChange={handlePricePerKgChange}
                  />
                </Grid>
              </>
            )}
            {newItem.value_type === ValueType.LENGTH && (
              <>
                <Grid size={3}>
                  <TextField
                    label="Длина от клиента (м)"
                    fullWidth
                    value={userMRaw}
                    onChange={handleUserMChange}
                  />
                </Grid>
                <Grid size={3}>
                  <TextField
                    label="Цена за метр (тг)"
                    fullWidth
                    value={pricePerMRaw}
                    onChange={handlePricePerMChange}
                  />
                </Grid>
              </>
            )}
            {newItem.value_type === ValueType.AREA && (
              <>
                <Grid size={3}>
                  <TextField
                    label="Площадь от клиента (м²)"
                    fullWidth
                    value={userAreaRaw}
                    onChange={handleUserAreaChange}
                  />
                </Grid>
                <Grid size={3}>
                  <TextField
                    label="Цена за м² (тг)"
                    fullWidth
                    value={pricePerAreaRaw}
                    onChange={handlePricePerAreaChange}
                  />
                </Grid>
              </>
            )}
            {newItem.value_type === ValueType.COUNT && (
              <>
                <Grid size={3}>
                  <TextField
                    label="Количество от клиента (шт)"
                    fullWidth
                    value={userCountRaw}
                    onChange={handleAmountChange}
                  />
                </Grid>
                <Grid size={3}>
                  <TextField
                    label="Цена за штуку (тг)"
                    fullWidth
                    value={pricePerPieceRaw}
                    onChange={handlePricePerPieceChange}
                  />
                </Grid>
              </>
            )}
            <Grid size={3}>
              <TextField
                label="Срок поставки"
                fullWidth
                value={newItem.delivery_time || ''}
                onChange={(e) => {
                  setNewItem(new CalculationItem({
                    ...newItem,
                    delivery_time: e.target.value
                  }));
                }}
              />
            </Grid>
            <Grid size={12}>
              <Divider sx={{borderColor: 'grey.500'}}>
                <Typography variant="subtitle1" color="textSecondary">
                  Результаты
                </Typography>
              </Divider>
            </Grid>
            <Grid size={3}>
              <TextField
                label="Округлённое количество"
                fullWidth
                value={newItem.amount || ''}
                disabled
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Масса (кг)"
                fullWidth
                value={newItem.rounded_kg || ''}
                disabled
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Длина (м)"
                fullWidth
                value={newItem.rounded_m || ''}
                disabled
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Площадь (м²)"
                fullWidth
                value={newItem.rounded_area || ''}
                disabled
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Цена за шт"
                fullWidth
                value={newItem.price_per_piece || ''}
                disabled
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Цена за кг"
                fullWidth
                value={newItem.price_per_kg || ''}
                disabled
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Цена за м"
                fullWidth
                value={newItem.price_per_m || ''}
                disabled
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Цена за м²"
                fullWidth
                value={newItem.price_per_area || ''}
                disabled
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Излишек клиента"
                fullWidth
                value={newItem.get_excess() || ''}
                disabled
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Сумма (тг)"
                fullWidth
                value={newItem.get_total() || ''}
                disabled
              />
            </Grid>
            <Grid container justifyContent="center" size={12}>
              <Grid size={5}/>
              <Grid size={2}>
                <Button variant="contained" fullWidth onClick={handleAddItem}>
                  Добавить
                </Button>
              </Grid>
              <Grid size={5}/>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {calc && (
        <Box sx={{flexGrow: 1, m: 3}}>
          <Grid container spacing={2}>
            <Grid size={3}>
              <TextField
                label="Наименование"
                value={calc?.title || ''}
                onChange={(e) => setCalc(new Calculation({...calc, title: e.target.value}))}
                fullWidth
                size={'small'}
              />
            </Grid>
            <Grid size={3}>
              <TextField
                label="Организация"
                value={calc?.organization || ''}
                onChange={(e) => setCalc(new Calculation({...calc, organization: e.target.value}))}
                fullWidth
                size={'small'}
              />
            </Grid>
            <Grid size={2}>
              <TextField
                label="Срок действия КП"
                value={calc?.validity_period || ''}
                onChange={(e) => setCalc(new Calculation({...calc, validity_period: e.target.value}))}
                fullWidth
                size={'small'}
              />
            </Grid>
            <Grid size={2}>
              <Select
                label="Статус"
                value={calc.status}
                onChange={(e) => setCalc(new Calculation({...calc, status: e.target.value}))}
                fullWidth
                size={'small'}
              >
                {Object.keys(statusMap).map((key) => (
                  <MenuItem key={key} value={key}>
                    {statusMap[key]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid size={1}>
              <FormControlLabel
                control={<Checkbox
                  checked={calc?.use_tax}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={(e) => setCalc(new Calculation({...calc, use_tax: e.target.checked}))}
                />}
                label="НДС"
              />
            </Grid>
            <Grid size={1}>
              { isLoading && <CircularProgress size="28px" /> }
            </Grid>
            <Grid size={6}>
              <TextField
                label="Полные данные покупателя (для счета на оплату)"
                value={calc?.buyer_full_info || ''}
                onChange={(e) => setCalc(new Calculation({...calc, buyer_full_info: e.target.value}))}
                fullWidth
                size={'small'}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                label="Договор (для счета на оплату)"
                value={calc?.agreement_info || ''}
                onChange={(e) => setCalc(new Calculation({...calc, agreement_info: e.target.value}))}
                fullWidth
                size={'small'}
              />
            </Grid>
            <Grid size={12}>
              <Box style={{height: '75vh', width: '100%'}}>
                <DataGrid
                  rows={calc.items}
                  columns={columns}
                  getRowId={(row) => row.id}
                  disableColumnSelector
                  style={{height: '100%'}}
                  hideFooterPagination
                  slots={{toolbar: CustomToolbar, footer: CustomFooterStatusComponent,}}
                  slotProps={{
                    footer: {total: calc?.getItemsTotal()},
                  }}
                  onRowClick={(params) => {
                    openItemToEdit(params.row.id);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default CalculationPage;