import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import Dashboard from "./pages/Dashboard";
import { AuthProvider, useAuth } from './logic/AuthContext';
import CalculationsTable from "./pages/CalculationsTable";

const PrivateRoute = () => {
  const user = useAuth();
  if (!user.token) return <Navigate to="/login" />;
  return <Outlet />;
};

const AuthRedirect = () => {
  const user = useAuth();
  return user.token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />;
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/nomenclatures" element={<Dashboard/>} />
            <Route path="/my-calculations" element={<Dashboard/>} />
            <Route path="/create-calculation" element={<Dashboard/>} />
            <Route path="/create-calculation/:id" element={<Dashboard/>} />
          </Route>
          <Route path="*" element={<AuthRedirect />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;