import React, { useState, useEffect } from 'react';
import {Box, Container} from '@mui/material';
import {DataGrid, GridColDef, GridRowParams, GridToolbar} from '@mui/x-data-grid';
import { useAuth } from '../logic/AuthContext';
import {useNavigate} from "react-router-dom";
import {Calculation, CalculationItem} from "../logic/Models";

const statusMap: { [key: string]: string } = {
  DRAFT: 'Черновик',
  PROJECT: 'Проект',
  READY: 'Готово',
  SENT: 'Отправлено',
};

const currencyFormatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'KZT',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const columns: GridColDef[] = [
  // { field: 'id', headerName: '#', width: 100},
  { field: 'title', headerName: 'Наименование', width: 200 },
  { field: 'organization', headerName: 'Организация', width: 200 },
  {
    field: 'status',
    headerName: 'Статус',
    width: 200,
    valueGetter: (value) => statusMap[value] || value,
  },
  {
    field: 'created_at',
    headerName: 'Дата создания',
    width: 200,
    valueGetter: (value) => {
      const date = new Date(value);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
  },
  {
    field: 'validity_period',
    headerName: 'Срок действия',
    width: 200,
  },
  {
    field: 'items_total',
    headerName: 'Сумма',
    width: 200,
    valueGetter: (value, row, ) => {
      return currencyFormatter.format(new Calculation(row).getItemsTotal());
    },
  }
];

const CalculationsTable: React.FC = () => {
  const [rows, setRows] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/v1/calculations/?page_size=1000000`, {
        headers: {
          'Authorization': `Token ${auth.token}`,
        },
      });
      const data = await response.json();
      setRows(data.results);
    };

    fetchData();
  }, [auth.token]);

  const handleRowClick = (params: GridRowParams) => {
    window.location.replace(`/create-calculation/${params.id}/`);
  };

  return (
    <Box style={{ height: '90vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        disableRowSelectionOnClick
        disableColumnSelector
        style={{height: '100%'}}
        //hideFooterPagination
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
            },
          },
        }}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};

export default CalculationsTable;