import React, { useState, useEffect } from 'react';
import {Box, Container} from '@mui/material';
import {DataGrid, GridColDef, GridToolbar} from '@mui/x-data-grid';
import { useAuth } from '../logic/AuthContext';

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Наименование', width: 300},
  // { field: 'gosts', headerName: 'ГОСТы', width: 300 },
  { field: 'size', headerName: 'Размер', width: 200 },
  { field: 'suffix', headerName: 'Суффикс', width: 150 },
  { field: 'kg_per_piece', headerName: 'Вес 1 шт (кг)', width: 120, valueGetter: (value) => value ? parseFloat(value) : "",},
  { field: 'price_per_kg', headerName: 'Цена за кг (тг)', width: 120, valueGetter: (value) => value ? parseFloat(value) : "", },
  { field: 'length_m', headerName: 'Длина 1 шт (м)', width: 150, valueGetter: (value, row) => row?.length_m ? parseFloat(value) : row?.length_description, },
  { field: 'price_per_m', headerName: 'Цена за м (тг)', width: 120, valueGetter: (value) => value ? parseFloat(value) : "", },
  { field: 'price_per_piece', headerName: 'Цена за шт (тг)', width: 120, valueGetter: (value) => value ? parseFloat(value) : "", },
  { field: 'area_per_piece', headerName: 'Площадь 1 шт (м²)', width: 150, valueGetter: (value) => value ? parseFloat(value) : "", },
  { field: 'price_per_area', headerName: 'Цена за м² (тг)', width: 120, valueGetter: (value) => value ? parseFloat(value) : "", },
];

const NomenclaturesTable: React.FC = () => {
  const [rows, setRows] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/v1/products/?page_size=1000000`, {
        headers: {
          'Authorization': `Token ${auth.token}`,
        },
      });
      const data = await response.json();
      setRows(data.results);
    };

    fetchData();
  }, [auth.token]);

  return (
    <Box style={{ height: '90vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        disableRowSelectionOnClick
        disableColumnSelector
        style={{height: '100%'}}
        // hideFooterPagination
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
            },
          },
        }}
      />
    </Box>
  );
};

export default NomenclaturesTable;