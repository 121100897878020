export enum ValueType {
  MASS = "Массе",
  LENGTH = "Длине",
  COUNT = "Количеству",
  AREA = "Площади"
}

const roundNum = (num: number) => {
  return Math.round(num * 100) / 100
}

const currencyFormatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'KZT',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export class Product {
  constructor(data: Partial<Product>) {
    Object.assign(this, data);
  }

  id?: number;
  created_by?: number; // Assuming 'users.User' is represented by a user ID
  created_at?: string; // ISO 8601 date string
  title?: string;
  gosts?: string[];
  size?: string | null;
  suffix?: string | null;
  kg_per_piece?: number | null;
  kg_per_m?: number | null;
  length_m?: number | null;
  length_description?: string | null;
  area_per_piece?: number | null;
  price_per_kg?: number | null;
  price_per_m?: number | null;
  price_per_piece?: number | null;
  price_per_area?: number | null;
  is_uncountable?: boolean = false;

  full_title(): string {
    return `${this.title} ${this.size} ${this.suffix} ${this.length_description ? this.length_description: ''}`;
  }
}

export class Calculation {
  constructor(data: Partial<Calculation>) {
    Object.assign(this, data);
    if (!this.validity_period) {
      this.validity_period = `до ${this.formatDate(this.addDays(new Date(), 3))}`;
    }
    this.items = this.items?.map(item => new CalculationItem(item));
  }

  id?: number;
  created_by?: number; // Assuming 'users.User' is represented by a user ID
  created_at?: string; // ISO 8601 date string
  title?: string | null;
  organization?: string | null;
  status?: string;
  items?: CalculationItem[] = [];
  validity_period?: string | null = '';
  use_tax?: boolean;
  buyer_full_info?: string | null;
  agreement_info?: string | null;

  toPlainObject() {
    return {
      ...this,
      items: this.items?.map(item => new CalculationItem(item).toPlainObject())
    };
  }

  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  getItemsTotal(): number {
    const summ = this.items?.reduce((acc, item) => acc + (new CalculationItem(item).get_total() || 0), 0) || 0;
    return roundNum(summ);
  }
}

export class CalculationItem {
  constructor(data: Partial<CalculationItem>) {
    Object.assign(this, data);
    this.product = this.product ? new Product(this.product) : null;
  }

  id?: number = Math.trunc(-Math.random() * 1000000); // Random negative number
  calculation?: number; // Assuming 'Calculation' is represented by its ID
  product?: Product | null; // Assuming 'Product' is represented by its ID
  amount?: number | null;
  user_kg?: number | null;
  rounded_kg?: number | null;
  user_m?: number | null;
  rounded_m?: number | null;
  value_type?: ValueType | string | null = ValueType.MASS;
  delivery_time?: string | null = '5 дней';
  price_per_kg?: number | null;
  price_per_m?: number | null;
  price_per_piece?: number | null;
  user_area?: number | null;
  rounded_area?: number | null;
  price_per_area?: number | null;

  custom_title?: string | null;
  custom_size?: string | null;
  custom_suffix?: string | null;
  custom_kg_per_piece?: number | null;
  custom_length_m?: number | null;
  custom_area_per_piece?: number | null;

  toPlainObject() {
    return {
      ...this,
      product: this.product?.id
    };
  }

  get_total() {
    if (this.user_kg && this.rounded_kg && this.price_per_kg) {
      return this.rounded_kg * this.price_per_kg;
    }
    if (this.user_m && this.rounded_m && this.price_per_m) {
      return this.rounded_m * this.price_per_m;
    }
    if (this.user_area && this.rounded_area && this.price_per_area) {
      return this.rounded_area * this.price_per_area;
    }
    if (this.amount && this.price_per_piece) {
      return this.amount * this.price_per_piece;
    }
  }

  get_excess() {
    if (this.value_type === ValueType.MASS && this.rounded_kg && this.user_kg) {
      return `${roundNum(this.rounded_kg - this.user_kg)} кг`;
    }
    if (this.value_type === ValueType.LENGTH && this.rounded_m && this.user_m) {
      return `${roundNum(this.rounded_m - this.user_m)} м`;
    }
    if (this.value_type === ValueType.AREA && this.rounded_area && this.user_area) {
      return `${roundNum(this.rounded_area - this.user_area)} м²`;
    }
    if (this.value_type === ValueType.COUNT) {
      return '0';
    }
  }

  get_title() {
    if (this.custom_title) {
      return `${this.custom_title} ${this.custom_size} ${this.custom_suffix}`;
    }
    return this.product?.full_title();
  }

  get_count() {
    if (this.user_kg && this.rounded_kg) {
      return `${roundNum(this.rounded_kg)} кг`;
    }
    if (this.user_m && this.rounded_m) {
      return `${roundNum(this.rounded_m)} м`;
    }
    if (this.user_area && this.rounded_area) {
      return `${roundNum(this.rounded_area)} м²`;
    }
    if (this.amount) {
      return `${this.amount} шт`;
    }
  }

  get_price_per_count() {
    if (this.user_kg && this.price_per_kg) {
      return currencyFormatter.format(this.price_per_kg)
    }
    if (this.user_m && this.price_per_m) {
      return currencyFormatter.format(this.price_per_m)
    }
    if (this.user_area && this.price_per_area) {
      return currencyFormatter.format(this.price_per_area)
    }
    if (this.amount && this.price_per_piece) {
      return currencyFormatter.format(this.price_per_piece)
    }
  }

  get_total_price() {
    if (this.user_kg && this.rounded_kg && this.price_per_kg) {
      return currencyFormatter.format(this.rounded_kg * this.price_per_kg)
    }
    if (this.user_m && this.rounded_m && this.price_per_m) {
      return currencyFormatter.format(this.rounded_m * this.price_per_m)
    }
    if (this.user_area && this.rounded_area && this.price_per_area) {
      return currencyFormatter.format(this.rounded_area * this.price_per_area)
    }
    if (this.amount && this.price_per_piece) {
      return currencyFormatter.format(this.amount * this.price_per_piece)
    }
  }
}